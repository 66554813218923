<template>
  <div class="min-h-screen min-w-screen flex flex-col">
    <div class="shadow-lg z-50">
      <Header class="pt-1 pb-1" v-if="!embed" >
        <template #logowithtext>
          <LogoWithText />
        </template>
      </Header>
    </div>
      <slot />
  </div>
</template>
<script setup lang="ts">
const route = useRoute();
const embed = ref(route.query.embed === "true" || false);
</script>
